import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import styles from './formCustomAutocomplete.module.scss'
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import "index.css";
import { useIsMobileSize } from "shared";

export const FormCustomAutocomplete = (props: any) => {
  const { 
    init, 
    array, 
    label, 
    zIndex, 
    renderOption,
    isOptionEqualToValue,
    getOptionDisabled,
    isChangeValue, 
    control, 
    name, 
    disabled, 
    handleBlur, 
    customOnChange,
    customSupportOnChange,
    customSetName,
    placeholder, 
    className,
    textAlign='start',
    isCurrency,
    setIsHiddenSwap,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [valueInit, setValueInit] = useState(init);

  useEffect(()=> {
    setValueInit(init)
  },[init])


  const handleOpen = () => {
      if(!disabled) {
        setIsOpen((prev) => !prev);
        setIsHiddenSwap(true);
      }
  }

  const handleSearch = (
    _: SyntheticEvent<Element, Event>, 
    newValue: string | any,
    onChange:any
  ) => {
    if(isChangeValue) {
      onChange(newValue.value)
      setValueInit(newValue.value)
    } else {
      onChange(newValue)
      setValueInit(newValue)
    }
  
    !!customSupportOnChange && customSupportOnChange()
    !!customOnChange && !!customSetName && customOnChange(name,customSetName)
    !!handleBlur && handleBlur()
    setIsOpen(false)
    setIsHiddenSwap(false)
  }

  const onChangeInputAutocomplete = (onChange:any) => () => {
    !isCurrency && onChange('');
  }

  const onMouseLeave = (
    value: string,
    onChange: (data: any) => void
    ) => () => {
    value === '' && onChange(valueInit);
    setIsOpen(false);
    setIsHiddenSwap(false);
  }

  
  return (
    <Box className={clsx(!!className && className, styles.wrapPopup)}>

      <Typography
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOpen()
          }
        }}
        tabIndex={0}
        role="button" 
        aria-label="button"
        aria-pressed="false"
        className={clsx(styles.showSearch, !!disabled ? styles.disabled : styles.unDisabled, isCurrency && isOpen && styles.hiddenInput)}
        onClick={handleOpen}>
          {valueInit || placeholder} 
      </Typography>
      
      {isOpen &&
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange, onBlur, ...fieldProps } }) => (
            <Box onMouseLeave={onMouseLeave(value, onChange)} style={{zIndex: zIndex}} className={clsx(styles.popup, textAlign==='end' && styles.bgTransparent)}>
              <Autocomplete
                {...fieldProps}
                ListboxProps={{
                  className: 'scrollbarCustom'
                }}
                open={true}
                disablePortal
                disableClearable
                forcePopupIcon={false}
                onOpen={() => true}
                getOptionDisabled={getOptionDisabled}
                fullWidth
                value={value}
                onChange={(e ,value) => handleSearch(e, value, onChange)}
                size='small'
                options={array}
                renderOption={renderOption}
                isOptionEqualToValue={isOptionEqualToValue}
                renderInput={(params) => (
                  <TextField
                    sx={{'.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                      textAlign:textAlign,
                      pt: '6px',
                      pr: '3px'
                    }}} 
                    onClick={onChangeInputAutocomplete(onChange)} 
                    focused 
                    label={label} 
                    placeholder={placeholder}  
                    {...params}
                  />
              )}
            />
          </Box>
        )}/>
      }
    </Box>
  )
}
