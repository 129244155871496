import { Box, Button, Typography } from "@mui/material";
import styles from './mobilePanel.module.scss';
import { checkSubmitConvert, DeliveryMethods,  handleError,  selectOption } from "shared";
import {ReactComponent as ArrowBottom} from 'assets/icon/smallArrowBottom.svg';
import clsx from "clsx";
import { useCallback, useRef, useReducer } from "react";
import { useFormContext } from "react-hook-form";
import { DividerSwapButton } from "../../dividerSwapButton/DividerSwapButton";
import { FormInputMoney } from "components";
import { WrapAutocompleteMobile } from "./wrapAutocompleteMobile";
import { isOptionEqual, isOptionEqualValue, renderOptionValueLabelAutocomplete } from "shared/helpers/mainHelpers/mainHelpers";
import { DEFAULT_FROM_CITY, DEFAULT_FROM_CURRENCY, DEFAULT_TO_CITY, DEFAULT_TO_CURRENCY } from "constants/MAIN";

export const MobilePanel = (props: any) => {
    const {
        handleConvertCurrency,
        errorFrom,
        errorTo,
        onSwap, 
        isError, 
        isDisabled,
        isOpenAutocomplete, 
        setIsOpenAutocomplete, 
        handleCurrency, 
        renderOptionAutocomplete, 
        toCity,
        fromCity,
        arrayCountryCity,
        handleSupportConvert,
        currencyFrom,
        currencyTo,
        arrayFromCurrency,
        arrayToCurrency,
        setFromCity,
        setToCity,
    } = props;

    const { control, getValues, setValue, formState:{ errors:formErrors } } = useFormContext();

    const reducerOpenSelect = (_:any, action:any) => {
        switch (action?.type) {
          case 'fromCity': {
            return {
                isFromCity: true,
                isToCity: false,
                isFromCurrency: false,
                isToCurrency: false,
            };
          }
          case 'toCity': {
            return {
                isFromCity: false,
                isToCity: true,
                isFromCurrency: false,
                isToCurrency: false,
            };
          }
          case 'fromCurrency': {
            return {
                isFromCity: false,
                isToCity: false,
                isFromCurrency: true,
                isToCurrency: false,
            };
          }
          case 'toCurrency': {
            return {
                isFromCity: false,
                isToCity: false,
                isFromCurrency: false,
                isToCurrency: true,
            };
          }
          default: 
            return {
              isFromCity: false,
              isToCity: false,
              isFromCurrency: false,
              isToCurrency: false,
            }
        }
      }
    
      const [stateIsOpen, setIsOpenDispatch] = useReducer(reducerOpenSelect, {isFromCity: false, isToCity: false, isFromCurrency: false, isToCurrency: false});
      const [stateIsOpenPortal, setIsOpenPortalDispatch] = useReducer(reducerOpenSelect, {isFromCity: false, isToCity: false, isFromCurrency: false, isToCurrency: false});

    const form = getValues();
    const  {from, to} = form;

    const handleOpenSlide = (e:any) => {
        e.stopPropagation();
        setIsOpenDispatch({ type: e.currentTarget.id });
        setIsOpenPortalDispatch({ type: e.currentTarget.id })
    }

  const prevFromCityRef = useRef(null);
  const prevFromCurrencyRef = useRef(null);
  const prevFromOptionRef = useRef(null);

  const prevToCityRef = useRef(null);
  const prevToCurrencyRef = useRef(null);
  const prevToOptionRef = useRef(null);

  const handleSetOptionFrom = useCallback((newValue: DeliveryMethods) => {
    const data = getValues();
        setValue('variable', 'crypto');

        prevFromCityRef.current = getValues('from.city');
        prevFromCurrencyRef.current = getValues('from.currency');
        prevFromOptionRef.current = getValues('from.option');

        if(newValue === DeliveryMethods.crypto) {
            setValue('variable', 'crypto');
            setValue('from.city', 'Tether (USDT)');
            setValue('from.currency', 'USDT');
            setFromCity('Tether (USDT)');
        } else {
            to.option !== DeliveryMethods.crypto && setValue('variable', 'cash');
            setValue('from.city', (prevFromCityRef.current !== 'Tether (USDT)' || prevFromCityRef.current === null ) ? prevFromCityRef.current : DEFAULT_FROM_CITY);
            setValue('from.currency', (prevFromCurrencyRef.current !== 'USDT' || prevFromCurrencyRef.current === null)? prevFromCurrencyRef.current : DEFAULT_FROM_CURRENCY);
            setFromCity((prevFromCityRef.current !== 'Tether (USDT)' || prevFromCityRef.current === null ) ? prevFromCityRef.current : DEFAULT_FROM_CITY);
        }
    if(checkSubmitConvert(data)) {
        handleConvertCurrency()
      }

  },[])

  const handleSetOptionTo = useCallback((newValue: DeliveryMethods) => {
    const data = getValues();

    prevToCityRef.current = getValues('to.city');
    prevToCurrencyRef.current = getValues('to.currency');
    prevToOptionRef.current = getValues('to.option');

    if(newValue === DeliveryMethods.crypto) {
        setValue('variable', 'crypto');
        setValue('to.city', 'Tether (USDT)')
        setValue('to.currency', 'USDT')
        setToCity('Tether (USDT)')
    } else {
        data.from.option !== DeliveryMethods.crypto && setValue('variable', 'cash')
        setValue('to.city', (prevToCityRef.current !== 'Tether (USDT)' && prevToCityRef.current !== null) ? prevToCityRef.current :DEFAULT_TO_CITY)
        setValue('to.currency',( prevToCurrencyRef.current !== 'USDT' && prevToCurrencyRef.current !== null) ? prevToCurrencyRef.current : DEFAULT_TO_CURRENCY)
        setToCity((prevToCityRef.current !== 'Tether (USDT)' && prevToCityRef.current !== null) ? prevToCityRef.current : DEFAULT_TO_CITY)
    }

    if(checkSubmitConvert(data)) {
        handleConvertCurrency()
      }
  },[])

  const onClosePortal = (e: any) => {
    e.stopPropagation();
    setIsOpenDispatch({type: 'close'})

    setTimeout(()=> {
        setIsOpenPortalDispatch({ type: 'close' })
    },200)
  }

  const arrFromCity = getValues('from.city').split(',');
  const arrToCity = getValues('to.city').split(',');

    return (
        <Box className={styles.mobilePanel}>
            <Box className={clsx(styles.inputs, (Boolean(formErrors?.from) || errorFrom.length > 1) && styles.error)}>
                <Box className={styles.wrapTypeCity}>
                    <Button onClick={handleOpenSlide} id='fromCity' variant="text" className={styles.typeTransfer}>
                        Отдаю 
                        <span className={clsx(styles.spanType, from.option === DeliveryMethods.crypto && styles.hidden)}>
                            {selectOption(from.option)} <ArrowBottom style={{marginLeft: '8px'}} />
                        </span>
                    </Button>
                    <Button onClick={handleOpenSlide} id='fromCity' variant="text" className={clsx(styles.defaultStylesButton, styles.city)}>
                        {
                            <>
                                <Typography className={clsx(styles.cityName)}>{arrFromCity[0]}{arrFromCity.length === 2 && ','}</Typography>
                               {!!arrFromCity[1] && <Typography className={clsx(styles.cityName)}>{arrFromCity[1]}</Typography>}
                            </>
                        }
                    </Button>
                </Box>
                
                <Box className={styles.inputsContainer}>
                    <Box className={clsx(styles.defaultStylesButton, styles.sum)} >
                        <FormInputMoney
                            className={clsx(styles.defaultStylesButton, styles.sum)}
                            handleBlur={handleConvertCurrency}
                            control={control} 
                            name={'from.sum'}
                            fullWidth 
                            placeholder={'50000'}
                            InputProps={{
                                inputProps: {
                                    style: { 
                                        padding: '0', 
                                        textAlign: 'right' 
                                    },
                                }
                            }}
                        />
                    </Box>
                    <Button onClick={handleOpenSlide} id='fromCurrency' variant="text" className={clsx(styles.defaultStylesButton, styles.currency)}>
                        {getValues('from.currency')}
                    </Button>
                </Box>
            </Box>

            <DividerSwapButton
                isDisabled={isDisabled}
                onSwap={onSwap}
                isError={isError} 
            />

            <Box className={clsx(styles.inputs, (Boolean(formErrors?.to) || errorTo.length > 1) && styles.error)}>
                <Box className={styles.wrapTypeCity}>
                    <Button onClick={handleOpenSlide} id='toCity' variant="text" className={styles.typeTransfer}>
                        Получаю 
                        <span className={clsx(styles.spanType, to.option === DeliveryMethods.crypto && styles.hidden)}>
                            {selectOption(to.option)} <ArrowBottom style={{marginLeft: '8px'}} />
                        </span>
                    </Button>
                    <Button  onClick={handleOpenSlide} id='toCity' variant="text" className={clsx(styles.defaultStylesButton, styles.city)}>
                    {
                            <>
                                <Typography className={clsx(styles.cityName)}>{arrToCity[0]}{arrToCity.length === 2 && ','}</Typography>
                                {!!arrToCity[1] && <Typography className={clsx(styles.cityName)}>{arrToCity[1]}</Typography>}
                            </>
                        }
                    </Button>
                </Box>
                
                <Box className={styles.inputsContainer}>
                    <Box className={clsx(styles.defaultStylesButton, styles.sum)} >
                        <FormInputMoney
                            handleBlur={handleConvertCurrency}
                            control={control} 
                            name={'to.sum'}
                            fullWidth 
                            prefix={'~ '} 
                            placeholder={'50000'}
                            disabled
                            InputProps={{
                                inputProps: {
                                    style: { 
                                        padding: '0', 
                                        textAlign: 'right' 
                                    },
                                }
                            }}
                        />
                    </Box>
                    <Button onClick={handleOpenSlide} id='toCurrency' variant="text" className={clsx(styles.defaultStylesButton, styles.currency)}>
                        {to.currency}
                    </Button>
                </Box>
            </Box>
            <>
            {
                stateIsOpenPortal.isFromCity &&
                 <WrapAutocompleteMobile
                    optionName='from.option'
                    disabledOption={to.option === DeliveryMethods.crypto}
                    onSetOption={handleSetOptionFrom}

                    convertCurrency={handleCurrency}
                    convertSetName={'from.currency'}

                    onClosePortal={onClosePortal}
                    isOpenAutocomplete={isOpenAutocomplete} 
                    setIsOpenAutocomplete={setIsOpenAutocomplete} 
                    placeholder='Выберите город'
                    renderOption={renderOptionAutocomplete}
                    isOptionEqualToValue={isOptionEqual}
                    name='from.city'
                    init={fromCity || getValues('from.city')}
                    zIndex='400'
                    arrayItems={getValues('from.city') === 'Tether (USDT)' ? ['Tether (USDT)'] :arrayCountryCity}
                    label="Поиск офиса по городам"
                    isFormOption
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    currencyFrom={currencyFrom}
                    stateIsOpen={stateIsOpen.isFromCity}
                />
            }
            {
                stateIsOpenPortal.isFromCurrency &&
                <WrapAutocompleteMobile
                    customSupportOnChange={handleSupportConvert}
                    control={control}
                    name='from.currency'
                    renderOption={renderOptionValueLabelAutocomplete}
                    isOptionEqualToValue={isOptionEqualValue}
                    isChangeValue
                    zIndex='400'
                    isCurrency
                    isHiddenSwap={isOpenAutocomplete}
                    setIsHiddenSwap={setIsOpenAutocomplete}
                    label='Выберите валюту'
                    onClosePortal={onClosePortal}
                    isOpenAutocomplete={isOpenAutocomplete} 
                    setIsOpenAutocomplete={setIsOpenAutocomplete} 
                    placeholder='Выберите город'
                    init={currencyFrom}
                    arrayItems={getValues('from.currency') === 'USDT' ? [{  label: 'Tether', value: 'USDT'}] :arrayFromCurrency}
                    stateIsOpen={stateIsOpen.isFromCurrency}
                />
            }

            {
                stateIsOpenPortal.isToCity &&
                 <WrapAutocompleteMobile
                    optionName='to.option'
                    disabledOption={from.option === DeliveryMethods.crypto}
                    onSetOption={handleSetOptionTo}
                    
                    convertCurrency={handleCurrency}
                    convertSetName={'to.currency'}

                    onClosePortal={onClosePortal}
                    isOpenAutocomplete={isOpenAutocomplete} 
                    setIsOpenAutocomplete={setIsOpenAutocomplete} 
                    placeholder='Выберите город'
                    renderOption={renderOptionAutocomplete}
                    isOptionEqualToValue={isOptionEqual}
                    name='to.city'
                    init={toCity || getValues('to.city')}
                    zIndex='400'
                    arrayItems={getValues('to.city') === 'Tether (USDT)' ? ['Tether (USDT)'] : arrayCountryCity}
                    label="Поиск офиса по городам"
                    isFormOption
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    stateIsOpen={stateIsOpen.isToCity}
                />
            }
            {
                stateIsOpenPortal.isToCurrency &&
                <WrapAutocompleteMobile
                    customSupportOnChange={handleSupportConvert}
                    control={control}
                    name='to.currency'
                    renderOption={renderOptionValueLabelAutocomplete}
                    isOptionEqualToValue={isOptionEqualValue}
                    isChangeValue
                    zIndex='400'
                    isCurrency
                    isHiddenSwap={isOpenAutocomplete}
                    setIsHiddenSwap={setIsOpenAutocomplete}
                    label='Выберите валюту'
                    onClosePortal={onClosePortal}
                    isOpenAutocomplete={isOpenAutocomplete} 
                    setIsOpenAutocomplete={setIsOpenAutocomplete} 
                    placeholder='Выберите город'
                    init={currencyTo}
                    arrayItems={getValues('to.currency') === 'USDT' ? [{  label: 'Tether', value: 'USDT'}] :arrayToCurrency}
                    stateIsOpen={stateIsOpen.isToCurrency}
                />
            }
            </>


            {(errorFrom || formErrors?.from) && 
               
               <Box sx={{top: '82px',  zIndex: '185'}} className={styles.errors}>
                   {
                       handleError(
                        errorFrom.length > 1 
                           ? errorFrom 
                           :   formErrors, currencyFrom)
                   }
               </Box>
             }
            {(errorTo || formErrors?.to) && 
               
               <Box sx={{top: '82px',  zIndex: '185'}} className={styles.errors}>
                   {
                       handleError(
                        errorTo.length > 1 
                           ? errorTo 
                           :   formErrors, currencyTo)
                   }
               </Box>
             }
        </Box>
    )
}