import { Box, Button, Divider, Typography } from "@mui/material";
import { CardHistoryProps } from "./cardHistory.interface";
import styles from './cardHistory.module.scss';
import clsx from "clsx";
import { STATUS_TRANSFER } from "../history.interface";
import { statusTranslete,  dateFormatter, formatMoney, useIsMobileSize } from "shared";
import { ReactComponent as Arrow} from 'assets/icon/arrowFromTo.svg';
import { ReactComponent as Repeat} from 'assets/icon/repeat.svg';
import { Link } from "react-router-dom";

export const CardHistory = (props: CardHistoryProps) => {
  const { item, itemLength } = props;

  return (
    <Box className={clsx(styles.card, itemLength <= 2 ? styles.fullWidth : styles.scroll)}>
      <Box className={styles.cardHeader}>
        <Box className={styles.infoHeader}>
          <Box className={styles.numberStatus}>
            <Typography className={styles.order}>ЗАЯВКА №{item.number}</Typography>
            <Box className={clsx(styles.status,
              item.status === STATUS_TRANSFER.IN_PROGRESS && styles.progress, 
              item.status === STATUS_TRANSFER.SUCCESSED && styles.successed,
              item.status === STATUS_TRANSFER.ERRORED && styles.cancelled,
              item.status === STATUS_TRANSFER.CANCELLED && styles.cancelled)}
            >
              <Typography className={styles.statusName}>
                {statusTranslete(item.status)}
              </Typography>
            </Box>
            
          </Box>
          <Typography className={styles.formatDate}>{dateFormatter(item.createdAt)}</Typography>
        </Box>
        { 
            item.cancelReason && 
            (item.status === STATUS_TRANSFER.ERRORED || item.status === STATUS_TRANSFER.CANCELLED) && 
            <Typography className={styles.cancelDesc}>
              {'Вы не приехали в нужное время Пожалуйста, напишите в чат.Вы не приехали в нужное время Пожалуйста, напишите в чат.'}
            </Typography>
          }
      </Box>
      <Box className={styles.cardBody}>
        <Box className={styles.cardBodyContent}>
            <Typography className={styles.amountCurrency}>{formatMoney(item.amountFrom)} {item.currencyFrom}</Typography>
            
            {
              (item.countryFrom && item.cityFrom) ?
                <Typography className={styles.formatDate}>{item.cityFrom} <span className={styles.slash}> /</span> <br className={styles.br}/> <span className={styles.country}>{item.countryFrom}</span></Typography>
                :
                <Typography className={styles.formatDate}>Tether</Typography>
            }
        </Box>
        <Arrow className={styles.arrow} />
        <Divider className={styles.divider}/>
        <Box className={styles.cardBodyContent}>
            <Typography className={styles.amountCurrency}>{formatMoney(item.amountTo)} {item.currencyTo}</Typography>
            {
              (item.countryTo && item.cityTo) 
              ?
                <Typography className={styles.formatDate}>{item.cityTo} <span className={styles.slash}> /</span>  <br className={styles.br}/> <span className={styles.country}>{item.countryTo}</span> </Typography>
              :
                <Typography className={styles.formatDate}>Tether</Typography>
            }
        </Box>
      </Box>
      <Box className={styles.cardFooter}>
        <Link className={styles.link} to={item.client.link}>
          <Typography className={styles.linkName}>ПЕРЕЙТИ В ЧАТ</Typography>
        </Link>
        {
          item.status !== STATUS_TRANSFER.IN_PROGRESS &&
            <Link to={'/'} state={item}>
              <Button className={styles.linkName} startIcon={<Repeat />}>ПОВТОРИТЬ</Button>
            </Link>
        }

      </Box>
    </Box>
  )
}
