import { Box, Typography } from "@mui/material"
import styles from './circle.module.scss';
import { CircleProps } from "./circle.interface";
import clsx from "clsx";

export const Circle = (props:CircleProps) => {
    const { name, description, styled} = props;
    return (
        <Box className={styles.circleWrap}>
            <Box className={clsx(styles.circle, styled)}>
                {typeof(name) === 'string' ? 
                <Typography className={styles.nameCircle}>
                    {name}
                </Typography>
                :
                    name
                }
            </Box>
            <Typography className={styles.descriptionCircle}>
                {description}
            </Typography>
        </Box>
    )
}