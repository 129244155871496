import { Box, Typography } from "@mui/material";
import styles from './about.module.scss'
import { DescriptionWrapper } from "components/descriptionWrapper/DescriptionWrapper";

export const About = () => {
  return (
    <Box className={styles.container}>
        <DescriptionWrapper>
            <Typography className={styles.pageTitle} variant="body1">
                О сервисе Empo.ai
            </Typography>

            <Typography className={styles.subtitle} variant="subtitle2">
                Привет! Добро пожаловать в <span className={styles.nameService}>Empo.ai</span> — место, где ваши деньги путешествуют по миру так же легко, как и вы. 
                Мы здесь, чтобы сделать международные переводы и обмен валют через USDT простыми, 
                безопасными и максимально удобными.
            </Typography>

            <Typography  className={styles.description} variant="subtitle1">
                Почему стоит выбрать Empo.ai?
            </Typography>

            <ul className={styles.priority}>
                <li>
                    <span className={styles.nameService}> Надежная безопасность: </span> 
                    Мы используем криптовалюту USDT, чтобы ваши средства были в полной безопасности.
                </li>
                <li>
                    <span className={styles.nameService}>Глобальный охват: </span> 
                    Нужно отправить деньги за границу? Мы работаем в более чем 150 странах.
                </li>
                <li>
                    <span className={styles.nameService}>Комфортные комиссии: </span> 
                    Мы предлагаем прозрачные условия, чтобы вы могли сохранять больше своих денег.
                </li>
                <li>
                    <span className={styles.nameService}>Легкость использования: </span> 
                    Никаких сложностей — только удобный интерфейс и поддержка, когда это необходимо.
                </li>
            </ul>

            <Typography className={styles.description} variant="subtitle2">
                В общем,  <span className={styles.nameService}>Empo.ai</span> — это ваш надежный партнер для быстрых, безопасных и беспроблемных финансовых операций по всему миру. Готовы начать? Давайте сделаем это вместе!
            </Typography>
        </DescriptionWrapper>
    </Box>
  )
}
