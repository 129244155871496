import { useEffect, useState } from "react";

export const useIsMobileSize = () => {
    const mobileSize = 768;
    const smallHeight = 960;
    const mobileSizeLow = 400;
    const [isSizeMobile, setIsSizeMobile] = useState(window.innerWidth <= mobileSize);
    const [isSizeMobileLow, setIsSizeMobileLow] = useState(window.innerWidth <= mobileSizeLow);
    const [isHeightMobile, setIsHeightMobile] = useState(window.innerHeight <= smallHeight);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsSizeMobile(window.innerWidth <= mobileSize);
            setIsSizeMobileLow(window.innerWidth <= mobileSizeLow);
            setIsHeightMobile(window.innerHeight <= smallHeight);
        });
        return () => {
            window.removeEventListener("resize", () => {
                setIsSizeMobile(window.innerWidth <= mobileSize);
                setIsSizeMobileLow(window.innerWidth <= mobileSizeLow);
                setIsHeightMobile(window.innerHeight <= smallHeight);
            })
        }
    }, []);
    
    return {isSizeMobile, isHeightMobile, isSizeMobileLow};
}