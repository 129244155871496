import { Box, Button, Link, Typography } from '@mui/material';
import { ReactComponent as Logo} from 'assets/icon/logo.svg';
import styles from './errorBoundaryPage.module.scss';

import { TgButton } from 'components';

export const ErrorBoundaryPage = () => {

    const reload = async() => {
        sessionStorage.setItem('mainPageStep', '0');
        window.location.reload();
    }

    return (
        <Box className={styles.pageError}>
            <Logo width={166} height={166}/>
            <Box className={styles.contentError}>
                <Typography variant="body1">
                    Опаньки, что-то <br/>
                    пошло не так
                </Typography>
                <Typography mt={'18px'} fontSize={16} variant="subtitle2">
                    Попробуйте еще раз повторить операцию <br/> или вернуться на главной страницу
                </Typography>

                <Button className={styles.buttonReload} onClick={reload} variant='contained' color='primary'>
                    ПОТОРИТЬ ЕЩЕ РАЗ
                </Button>

                <Link href={'https://t.me/emposupport'} sx={{width: '100%'}}>
                    <TgButton styled={{marginTop: '24px'}} />
                </Link>
            </Box>
        </Box>
    )
}
