import '../../../App.css';

export const Burger = () => {

    return (
        <div className='nav-toggle'>
            <span className='bar'></span>
            <span className='bar'></span>
            <span className='bar'></span>
        </div>
    )
}