import { Autocomplete, Box, IconButton, TextField } from '@mui/material'
import  { SyntheticEvent } from 'react'
import { Controller } from 'react-hook-form'
import styles from './formAutocompleteMobile.module.scss';
import {ReactComponent as Cross} from 'assets/icon/cross.svg';

export const FormAutocompleteMobile = (props: any) => {
    const {
        closePortal, 
        array, 
        label, 
        renderOption,
        isOptionEqualToValue,
        getOptionDisabled,
        isChangeValue, 
        control, 
        name, 
        handleBlur, 
        convertCurrency,
        customSupportOnChange,
        convertSetName,
        placeholder, 
        textAlign='start',
        setIsHiddenSwap,
      } = props;


        const handleSearch = (
            e: SyntheticEvent<Element, Event>, 
            newValue: string | any,
            onChange:any
          ) => {
            
            if(isChangeValue) {
              onChange(newValue.value)
            } else {
              onChange(newValue)
            }
          
            !!customSupportOnChange && customSupportOnChange()
            !!convertCurrency && !!convertSetName && convertCurrency(name,convertSetName)
            !!handleBlur && handleBlur()
            closePortal(e)
            setIsHiddenSwap(false)
          }

    return (
        <Box className={styles.wrapForm}>
            <Box className={styles.controllerWrap}>
                <Controller
                  control={control}
                  name={name}
                  render={({ field: { value, onChange, onBlur, ...fieldProps } }) => (
                    <Autocomplete
                        {...fieldProps}
                        ListboxProps={{
                          className: 'scrollbarCustom'
                        }}
                        disablePortal
                        open={true}
                        disableClearable
                        forcePopupIcon={false}
                        onOpen={() => true}
                        getOptionDisabled={getOptionDisabled}
                        fullWidth
                        value={value}
                        onChange={(e ,value) => handleSearch(e, value, onChange)}
                        size='small'
                        options={array}
                        renderOption={renderOption}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderInput={(params) => (
                          <TextField
                            sx={{'.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                              textAlign:textAlign,
                              pt: '6px',
                              pr: '3px'
                            }}} 
                            focused 
                            label={label} 
                            placeholder={placeholder}  
                            {...params}
                          />
                        )}
                      />
                  )}/>
                </Box>

                <IconButton className={styles.close} onClick={closePortal} aria-label="closePortal">
                    <Cross />
                </IconButton>
        </Box>
    )
}
