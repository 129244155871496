import { Box, Button, Checkbox, Link, Typography } from '@mui/material';
import styles from './selectTransfer.module.scss'
import { MouseEvent, useCallback, useRef, useState, memo} from 'react';
import { CardSubstrate, } from 'components';
import { options } from 'constants/MOCK_CONST';
import { FormToggleButton } from 'components/formToggleButton/FormToggleButton';
import { Controller, useFormContext } from 'react-hook-form';
import { baseApi } from 'api';
import { checkSubmitConvert, DeliveryMethods, removeMargin, variableTransferConvert } from 'shared';
import { WelcomeCards } from './welcomeCards';
import { PanelTransferMoney } from './panelTransferMoney';
import { HowToWork } from './howToWork';
import { DEFAULT_FROM_CITY, DEFAULT_FROM_CURRENCY, DEFAULT_TO_CITY, descriptionMainPage } from 'constants/MAIN';

export const SelectTransfer = memo((props:any) => {
  const { control, getValues, setValue } = useFormContext();
  const {handleNext, isStateLink} = props;
  const [fromCity, setFromCity] = useState(getValues('from.city'));
  const [toCity, setToCity] = useState(getValues('to.city'));
  const [currencyFrom, setCurrencyFrom] = useState(getValues('from.currency'));
  const [currencyTo, setCurrencyTo] = useState(getValues('to.currency'));
  const [errorFrom, setErrorFrom] = useState<string>('');
  const [errorTo, setErrorTo] = useState<string>('');

  const clearResponseError = () => {
    setErrorFrom('');
    setErrorTo('');
  }


  const handleConvertCurrency = useCallback(async() => {
    clearResponseError()
    const data = getValues();
    

    if(data?.from.currency === data?.to?.currency || 
      (data?.from.currency === 'USDT' && data?.to?.currency === 'USD') ||
      (data?.from.currency === 'USD' && data?.to?.currency === 'USDT')
    ) {

      const mock_sum = removeMargin(data?.from?.sum)
      
      setValue('to.sum', Math.floor(mock_sum));
      return;
    }

    const request = variableTransferConvert(data);

    if(
      checkSubmitConvert(data)
    ) {
        const response = await baseApi.convertCurrencyAndValidate(request);
        if(response.result) {
          const value = removeMargin(response.result)
          setValue('to.sum', Math.floor(value));
          return {from: false, to: false}
      } else {
          switch (response?.type) {
            case 'from':
              setErrorFrom(response?.message)
              return {from: true, to: false}
            case 'in':
              setErrorTo(response?.message)
              return {from: false, to: true}
            default:
                setErrorTo(response?.message)
                return {from: false, to: true}
            }
      }
    } else {
      return {from: true, to: true}
    }
},[])

  const prevFromCityRef = useRef(null);
  const prevFromCurrencyRef = useRef(null);

  const prevToCityRef = useRef(null);
  const prevToCurrencyRef = useRef(null);
  const prevToOptionRef = useRef(null);

  const handleSetVariable = useCallback((newValue: string, option?: DeliveryMethods) => {
    const data = getValues();
    if(newValue === 'crypto') {
      prevFromCityRef.current = getValues('from.city')
      prevFromCurrencyRef.current = getValues('from.currency');
      prevToCityRef.current = getValues('to.city');
      prevToCurrencyRef.current = getValues('to.currency');
      prevToOptionRef.current = getValues('to.option');

      setValue('from.option', 'crypto');
      setValue('from.city', 'Tether (USDT)');
      setValue('from.currency', 'USDT');
      setFromCity('Tether (USDT)');
      setToCity(prevToCityRef.current || DEFAULT_FROM_CITY);
    } else {
      setFromCity(prevFromCityRef.current || DEFAULT_FROM_CITY);
      setToCity(prevToCityRef.current || DEFAULT_TO_CITY);
      setValue('from.city', prevFromCityRef.current || DEFAULT_FROM_CITY);
      setValue('from.currency', prevFromCurrencyRef.current || DEFAULT_FROM_CURRENCY);
      setValue('from.option', option || 'office');
      setValue('to.city', prevToCityRef.current || DEFAULT_TO_CITY);
      setValue('to.currency',prevToCurrencyRef.current || DEFAULT_FROM_CURRENCY);
      setValue('to.option',  'courier');
    }

    
    if(checkSubmitConvert(data)) {
      handleConvertCurrency()
    }

    clearResponseError();
  },[]);

  const onChangeData = useCallback(() => {
    setCurrencyFrom(getValues('from.currency'));
    setCurrencyTo(getValues('to.currency'));
    setToCity(getValues('to.city'));
    setFromCity(getValues('from.city'))
  }, []);


  const handleSwap = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const data = getValues();

    setFromCity(data.to.city);
    setToCity(data.from.city);
    setCurrencyFrom(data.to.currency);
    setCurrencyTo(data.from.currency);

    setValue('from', data.to);
    setValue('to', data.from);


    onChangeData();

    if(
        checkSubmitConvert(data)
    ) {
        handleConvertCurrency()
    }
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <CardSubstrate styled={styles.substrate}>
      <Box className={styles.baseCard}>
        <Box>
          <Typography variant="h1">
            ФИНАНСОВАЯ
            <br/>
            <span className={styles.blueWord}>ЛОГИСТИКА</span> 
            <br/>
            ПО ВСЕМУ МИРУ
          </Typography>

          <Typography className={styles.crypto}>
            WITH CRYPTO
          </Typography>

          <Typography className={styles.cryptoDesc} >
            {descriptionMainPage}
          </Typography>
        </Box>

        <Box className={styles.transferSelects}>
          <FormToggleButton control={control} name={'variable'} setValue={handleSetVariable} array={options}/>

            <PanelTransferMoney
              handleSetVariable={handleSetVariable}
              isStateLink={isStateLink}
              errorFrom={errorFrom}
              errorTo={errorTo}
              handleConvertCurrency={handleConvertCurrency}
              clearResponseError={clearResponseError}
              fromCity={fromCity}
              toCity={toCity}
              setFromCity={setFromCity}
              setToCity={setToCity}
              onChangeData={onChangeData}
              currencyFrom={currencyFrom}
              currencyTo={currencyTo}
              setCurrencyTo={setCurrencyTo}
              setCurrencyFrom={setCurrencyFrom}
              onSwap={handleSwap}
            />

          <Box className={styles.nextContent}>
            <Controller
              name='rules'
              control={control}
              rules={{required: true}}
              render={({ field: props, fieldState: { error } }) => (
                <Box className={styles.checkboxRules}>
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                  <Typography textTransform={'none'}  fontSize={16} variant="subtitle2">
                    {`Согласен ${' '}`}
                    <Link sx={ error && {color: 'red', textDecorationColor: 'red'}} href='/exchange-rules'>c условиями сервиса</Link>
                  </Typography>
                </Box>
              )}
            />

            <Button disabled={errorFrom.length > 1 || errorTo.length > 0} className={styles.buttonNext} onClick={handleNext} variant="contained">Продолжить</Button>
          </Box>
        </Box>

        <Typography className={styles.cryptoDescSmall} >
            {descriptionMainPage}
        </Typography>
      </Box>
      </CardSubstrate>

      <HowToWork />

      <WelcomeCards />
    </Box>

  )
})
