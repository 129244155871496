import { Box, Typography } from "@mui/material";
import styles from './footer.module.scss';
import { Link } from "react-router-dom";
import clsx from "clsx";
import {ReactComponent as LogoFooter} from 'assets/icon/grayLogoFooter.svg';
import {ReactComponent as BlackStar} from 'assets/icon/blackStar.svg';
import { TRUST_PILOT_LINK } from "shared";

export const Footer = () => {
    return (
        <Box className={styles.footerWrap}>
            <Box className={styles.containerFooter}>
                <Box className={styles.links}>
                    <Box className={styles.containerLinks}>
                        <Box className={styles.blockLinks}>
                            <Typography className={styles.title} variant="subtitle1">О СЕРВИСЕ</Typography>
                            <Link className={styles.link} to={'/about'}>
                                Описание
                            </Link>
                            <Link className={styles.link} to={'/exchange-rules'}>
                                Правила обмена
                            </Link>
                            <Link className={styles.link} to={'https://t.me/emposupport'}>
                                Поддержка
                            </Link>
                            <Link to={"/"} className={styles.logoFooter}>
                                <LogoFooter />
                            </Link>
                        </Box>
                        <Box className={styles.blockLinks}>
                            <Typography className={styles.title} variant="subtitle1">ВОПРОСЫ И ОТВЕТЫ</Typography>
                            <Link className={styles.link} to={'/FAQ'} state={{open: 0}}>
                                Переводы
                            </Link>
                            <Link className={styles.link} to={'/FAQ'} state={{open: 1}}>
                                Покупка/Продажа USDT
                            </Link>
                        </Box>
                        <Box className={styles.blockLinks}>
                            <Typography className={styles.title} variant="subtitle1">ОТЗЫВЫ</Typography>
                            <Link className={styles.link} to={TRUST_PILOT_LINK}>
                                Отзывы на TrustPilot
                            </Link>
                            <Typography className={clsx(styles.title, styles.social) } variant="subtitle1">СОЦСЕТИ</Typography>
                            <Link className={styles.link} to={'https://t.me/empoai'}>
                                Новости в Telegram
                            </Link>
                        </Box>
                    </Box>
                        <Typography className={styles.description}>
                            <span className={styles.span}>Empo </span>- Перестановки по всеми миру через криптовалюты. Безопасно. Быстро. 
                            Сopyright 2024. All rights reserved. 
                        </Typography>
                </Box>
                <Box className={styles.trustPilot}>
                    <Link className={styles.cardLink} to={TRUST_PILOT_LINK}>
                        <Box className={styles.trustPilotCard}>
                            <Typography className={styles.cardTitle}>ОЦЕНИТЕ НА <br/>TRUSTPILOT</Typography>
                            <Box className={styles.stars}>
                                <BlackStar width={'21px'} height={'21px'} />
                                <BlackStar width={'21px'} height={'21px'} />
                                <BlackStar width={'21px'} height={'21px'} />
                                <BlackStar width={'21px'} height={'21px'} />
                                <BlackStar width={'21px'} height={'21px'} />
                            </Box>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </Box>
    )

}