import { Button } from '@mui/material';
import styles from './tgButton.module.scss';
import TelegramIcon from '@mui/icons-material/Telegram';
import clsx from 'clsx';

export const TgButton = (props: any) => {
    const {title='ПОДДЕРЖКА', styled, classNames} = props;

    return (
        <Button
            style={{backgroundColor: '#039BE5', color: '#FFFFFF', ...styled}}
            className={clsx(styles.buttonSupport, classNames && classNames)}
            startIcon={<TelegramIcon className={styles.tgIcon} />}
            >
            {title}
        </Button>
    )
}
