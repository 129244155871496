import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { FormCustomAutocomplete, FormInputMoney, FormSelect } from 'components'
import { useFormContext } from 'react-hook-form'
import { handleError, moneyTip } from 'shared'
import styles from './webPanel.module.scss'
import { MOCK_VARIABLE } from 'constants/MOCK_CONST'
import { isOptionEqual, isOptionEqualValue, renderOptionValueLabelAutocomplete } from 'shared/helpers/mainHelpers/mainHelpers'
import { DividerSwapButton } from '../../dividerSwapButton/DividerSwapButton'

export const WebPanel = (props: any) => {
    const { control, getValues, setValue, formState:{ errors } } = useFormContext();

    const {
        errorFrom,
        errorTo,
        isFocusMoneyFrom, 
        fromCity, 
        isOpenAutocomplete, 
        setIsOpenAutocomplete,
        handleCurrency,
        renderOptionAutocomplete,
        arrayCountryCity,
        handleConvertCurrency,
        onFocusMoney,
        removeFocusMoney,
        handleSupportConvert,
        currencyFrom,
        arrayFromCurrency,
        minOutCurrency,
        toCity,
        onSwap,
        currencyTo,
        arrayToCurrency,
    } = props;
  return (
    <Box className={styles.panel} >
            <Box className={clsx((Boolean(errors?.from) || errorFrom.length > 1) ? styles.panelErrorsTop : styles.borderTopPanel, styles.panelInputs, isFocusMoneyFrom && styles.focusMoneyInput)} >
                <Box className={styles.selects}>
                    <Box className={styles.selectType}>
                        <Typography sx={{color: '#C2C2C2'}} variant='subtitle2'>Отдаю</Typography>
                        {
                            (fromCity !== 'Tether (USDT)') &&
                            <Box className={styles.wrapSelect}>
                                <FormSelect
                                    size="small"
                                    fullWidth={false}
                                    control={control}
                                    name={'from.option'}
                                    menuItems={MOCK_VARIABLE}
                                />
                            </Box>
                        }
                    </Box>
                    <FormCustomAutocomplete
                        isHiddenSwap={isOpenAutocomplete}
                        setIsHiddenSwap={setIsOpenAutocomplete}
                        getValuesForm={getValues}
                        setValueForm={setValue}
                        customOnChange={handleCurrency}
                        customSetName='from.currency'
                        getOptionDisabled={(option: string) =>
                            option === getValues('to.city')
                        }
                        isShowTitle
                        placeholder={'Выберите город'}
                        renderOption={renderOptionAutocomplete}
                        isOptionEqualToValue={isOptionEqual}
                        control={control}
                        name={'from.city'}
                        disabled={getValues('from.city') === 'Tether (USDT)'}
                        zIndex='400'
                        init={fromCity || getValues('from.city')} 
                        array={arrayCountryCity} 
                        label="Поиск офиса по городам" 
                    />
                </Box>
                <Box className={styles.inputSum}>
                    <FormInputMoney
                        handleBlur={handleConvertCurrency}
                        disabled={fromCity === null}
                        control={control} 
                        name={'from.sum'}
                        fullWidth 
                        placeholder={'50000'}
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                        }}
                        onFocus={onFocusMoney}
                        onBlur={removeFocusMoney}
                    />
                    <FormCustomAutocomplete
                        customSupportOnChange={handleSupportConvert}
                        disabled={getValues('from.currency') === 'USDT' || fromCity === null}
                        control={control}
                        name={'from.currency'}
                        renderOption={renderOptionValueLabelAutocomplete}
                        isOptionEqualToValue={isOptionEqualValue}
                        isChangeValue={true}
                        zIndex='400'
                        init={currencyFrom}
                        array={arrayFromCurrency}
                        textAlign='end'
                        isCurrency
                        isHiddenSwap={isOpenAutocomplete}
                        setIsHiddenSwap={setIsOpenAutocomplete}
                    />
                </Box>
            </Box>

            {((Boolean(errors?.from) || errorFrom.length > 1 ) || isFocusMoneyFrom) && 
            <>
                { 
                    isFocusMoneyFrom ? 
                    <Box className={styles.focusMoneyDescription} >
                        {moneyTip(currencyFrom, minOutCurrency)}
                    </Box>
                :
                    <Box sx={{top: '82px',  zIndex: '185'}} className={styles.errors}>
                        {
                            handleError(errorFrom.length > 1 
                                ? errorFrom 
                                :   errors, currencyFrom)
                        }
                    </Box>
                }
            </>
            }

            <DividerSwapButton
                isDisabled={fromCity === null || toCity === null}
                onSwap={onSwap}
                isError={errorFrom.length === 0 && errorTo.length === 0 && !isFocusMoneyFrom && !isOpenAutocomplete && !Boolean(errors?.from) } 
            />
            
            <Box className={
                clsx(((Boolean(errors?.to) === true || errorTo.length > 1) && !isFocusMoneyFrom) ? styles.panelErrorsBottom : styles.borderBottomPanel, styles.panelInputs)}>
                <Box className={styles.selects}>
                    <Box className={styles.selectType}>
                        <Typography sx={{color: '#C2C2C2'}} variant='subtitle2'>Получаю</Typography>

                        {
                            (toCity !== 'Tether (USDT)') &&
                            <Box className={styles.wrapSelect}>                            
                                <FormSelect
                                    size="small"
                                    fullWidth={false}
                                    control={control}
                                    name={'to.option'}
                                    menuItems={MOCK_VARIABLE}
                                />
                            </Box>
                        }

                        </Box>
                       
                    <FormCustomAutocomplete
                        isHiddenSwap={isOpenAutocomplete}
                        setIsHiddenSwap={setIsOpenAutocomplete}
                        customOnChange={handleCurrency}
                        customSetName='to.currency'
                        getOptionDisabled={(option: string) =>
                            option === getValues('from.city')
                          }
                        isShowTitle
                        control={control}
                        disabled={getValues('to.city') === 'Tether (USDT)'}
                        placeholder={'Выберите город'}
                        renderOption={renderOptionAutocomplete}
                        isOptionEqualToValue={isOptionEqual}
                        name={'to.city'}
                        zIndex='400' 
                        init={toCity || getValues('to.city')} 
                        array={arrayCountryCity} 
                        label="Поиск офиса по городам" />
                </Box>
                <Box className={styles.inputSum}>
                    <FormInputMoney 
                        control={control} 
                        name={'to.sum'}
                        disabled
                        prefix='~ '
                        fullWidth 
                        placeholder={'~9999'} 
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "right" },
                            }
                    }} />
                     <FormCustomAutocomplete
                        setIsHiddenSwap={setIsOpenAutocomplete}
                        customSupportOnChange={handleSupportConvert}
                        disabled={getValues('to.currency') === 'USDT' || toCity === null}
                        control={control}
                        renderOption={renderOptionValueLabelAutocomplete}
                        isOptionEqualToValue={isOptionEqualValue}
                        name={'to.currency'}
                        isChangeValue={true}
                        zIndex='400' 
                        init={currencyTo} 
                        array={arrayToCurrency} 
                        isCurrency
                        textAlign='end'
                    />
                </Box>
            </Box>
            {
               !isFocusMoneyFrom &&
               <>
               {(Boolean(errors.to) === true || errorTo.length > 1) && 
                <Box sx={{top: '170px', zIndex: '170'}} className={styles.errors}>
                    {
                        handleError(errorTo.length > 1 
                        ? 
                            errorTo 
                        : 
                            errors, currencyTo)
                    }
                </Box>
               } </>
                
            }
        </Box>
  )
}
