import { Button, Typography } from '@mui/material';
import { CardSubstrate } from 'components';
import { Link } from 'react-router-dom';
import styles from './emptyHistory.module.scss'

export const EmptyHistory = () => {
    return (
        <CardSubstrate styled={styles.content}>
            <>
                <Typography className={styles.titleNoHistory}>
                    Истории заявок еще нет.<br/> Создадим историю вместе?
                </Typography>
                <Typography className={styles.subTitleNoHistory} variant='subtitle2'>
                    Перейдите на главную чтобы <br/> сделать это впервые
                </Typography>
                <Link to={'/'} className={styles.link}>
                    <Button className={styles.buttonLinkMain} variant='contained'>
                        СОЗДАТЬ ЗАЯВКУ
                    </Button>
                </Link>
            </>
        </CardSubstrate>
    )
}
