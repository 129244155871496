import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { AccordionComponentProps } from './accordionComponent.interface';


export const AccordionComponent = (props: AccordionComponentProps) => {

  const {arrAccordion, styled} = props;
  
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleExpanded =
    (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
  };
    
  return (
    <div>
      {
        !!arrAccordion &&
          arrAccordion.map((el)=> (
            <Accordion key={el.id} className={clsx(styled)} expanded={expanded === el.id} onChange={handleExpanded(el.id)}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon color='success' />}
            aria-controls={`panel${el.id}bh-content`}
            id={`panel-${el.id}`}
          >
            {el.summary}
            </AccordionSummary>
            <AccordionDetails>
              {el.details}
            </AccordionDetails>
            </Accordion>
          ))
      }
    </div>
  );
}
