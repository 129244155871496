import { Outlet } from 'react-router-dom';
import { Header } from './header';
import styles from './layout.module.scss';
import ErrorBoundary from 'shared/ErrorBoundary/ErrorBoundary';
import { Footer } from './footer';

export const Layout = () => {
  return (
    <div className={styles.layout}>
      <header className={styles.head}>
        <Header/>
      </header>
      <ErrorBoundary>
        <main className={styles.main}><Outlet /></main>
        <footer className={styles.footer}><Footer /></footer>
      </ErrorBoundary>
    </div>
  )
}
