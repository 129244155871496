import { Box } from '@mui/material'
import { AccordionComponent } from 'components'
import React from 'react'
import { arrCrypto, arrInternational } from 'constants/FAQ';
import styles from './contentAccordion.module.scss'
import { ContentProps } from './contentAccordion.interface';

export const ContentAccordion = (props: ContentProps) => {
    
    const selectFaq = (selected:number) => {
        switch (selected) {
            case 0:
                return arrInternational
            case 1:
                return arrCrypto
        
            default:
                return null
        }
    }
    
  return (
    <Box className={styles.contentFaq}>
        <AccordionComponent styled={styles.accordionStyle} arrAccordion={selectFaq(props.open)}/>
    </Box>
  )
}
