import { useCallback, useEffect, useState, memo } from 'react';
import { useIsMobileSize } from 'shared';
import { baseApi } from 'api';
import { checkSubmitConvert, renderOptionAutocomplete } from 'shared';
import { CountryAndCity, SelectCurrency } from 'api/api.interface';
import { useFormContext } from 'react-hook-form';
import { WebPanel } from './webPanel';
import { MobilePanel } from './mobilePanel';
import { DEFAULT_FROM_CITY, DEFAULT_FROM_CURRENCY, DEFAULT_TO_CITY, DEFAULT_TO_CURRENCY } from 'constants/MAIN';

export const PanelTransferMoney = memo((props: any) => {
    const { getValues, setValue, formState:{ errors } } = useFormContext();

    const { 
        fromCity,
        toCity,
        onChangeData,
        currencyFrom,
        currencyTo,
        setCurrencyFrom,
        setCurrencyTo,
        errorFrom,
        errorTo,
        handleConvertCurrency,
        clearResponseError,
        isStateLink,
        onSwap,
        handleSetVariable,
        setFromCity,
        setToCity,
    } = props;

    const [arrayCountryCity, setArrayCountryCity] = useState<any[]>([]);
    const [arrayFromCurrency, setArrayFromCurrency] = useState<any[]>([]);
    const [arrayToCurrency, setArrayToCurrency] = useState<any[]>([]);
    const [minOutCurrency, setMinOutCurrency] = useState<any[]>([]);
    const [isFocusMoneyFrom, setIsFocusMoneyFrom] = useState(false);
    const [isOpenAutocomplete, setIsOpenAutocomplete] = useState(false);

    useEffect(()=> {
        (async()=>{
          const responseLimit = await baseApi.getLimitTransfer();
          if(Array.isArray(responseLimit)) {
            setMinOutCurrency(responseLimit)
          }
        })()
      },[])
    

    useEffect(()=> {
        setCurrencyFrom(getValues('from.currency'));
        setCurrencyTo(getValues('to.currency'));
    }, [errors, getValues('from.currency'), getValues('to.currency')]);

    useEffect(()=> {
        (async() => {
            const responseCountryCity = await baseApi.countryCity();
            
            setArrayCountryCity(responseCountryCity?.map((el:CountryAndCity) => `${el.country}, ${el.city}`));

            if(!isStateLink && fromCity === DEFAULT_FROM_CITY && toCity === DEFAULT_TO_CITY && currencyFrom === DEFAULT_FROM_CURRENCY && currencyTo === DEFAULT_TO_CURRENCY) {
                const elementFromDefault = responseCountryCity.find((el: CountryAndCity) => el.city === 'Москва');
                const elementToDefault = responseCountryCity.find((el: CountryAndCity) => el.city === 'Дубай');
                setValue('from.city', `${elementFromDefault?.country}, ${elementFromDefault?.city}`);
                setValue('to.city', `${elementToDefault?.country}, ${elementToDefault?.city}`);
           
                handleCurrency('from.city', 'from.currency')
                handleCurrency('to.city', 'to.currency')
            }

        })()
    },[])

    const handleCurrency = useCallback( async(getName: string, setName: string) => {
        clearResponseError()
        const data = getValues(getName);

        const response = await baseApi.getCurrencyList({
            country: data?.split(',')[0]?.trim(),
            city: data?.split(',')[1]?.trim() 
        })

        setName.includes('from') ? 
        setArrayFromCurrency(response?.map((el:SelectCurrency) => ({ 
            label: el.label, 
            value: el.value
        })))
        :
        setArrayToCurrency(response?.map((el:SelectCurrency) => ({ 
            label: el.label, 
            value: el.value
        })))

        setValue(setName, response.at(0)?.value);

        const dataConvert = getValues();
        if(
            checkSubmitConvert(dataConvert)
        ) {
            handleConvertCurrency()
        }
        onChangeData()
       
    }, []);

    const handleSupportConvert = () => {
        const dataConvert = getValues();
        if(
            checkSubmitConvert(dataConvert)
        ) {
            
            handleConvertCurrency()
        }
    }

    const removeFocusMoney = useCallback(() =>  {
        setIsFocusMoneyFrom(false);
    },[]);

    const onFocusMoney = useCallback(() =>  {
        setIsFocusMoneyFrom(true)
    },[]);

    const { isSizeMobile } = useIsMobileSize();

    return (
        <>
            {
                isSizeMobile ?
                    <>
                        <MobilePanel
                            setFromCity={setFromCity}
                            setToCity={setToCity}
                            handleSetVariable={handleSetVariable}
                            onSwap={onSwap}
                            isError={errorFrom.length === 0 && errorTo.length === 0 && !Boolean(errors?.from)} 
                            isDisabled={fromCity === null || toCity === null} 
                            errorFrom={errorFrom}
                            errorTo={errorTo}
                            isFocusMoneyFrom={isFocusMoneyFrom} 
                            fromCity={fromCity} 
                            isOpenAutocomplete={isOpenAutocomplete} 
                            setIsOpenAutocomplete={setIsOpenAutocomplete}
                            handleCurrency={handleCurrency}
                            renderOptionAutocomplete={renderOptionAutocomplete}
                            arrayCountryCity={arrayCountryCity}
                            handleConvertCurrency={handleConvertCurrency}
                            onFocusMoney={onFocusMoney}
                            removeFocusMoney={removeFocusMoney}
                            handleSupportConvert={handleSupportConvert}
                            currencyFrom={currencyFrom}
                            arrayFromCurrency={arrayFromCurrency}
                            minOutCurrency={minOutCurrency}
                            toCity={toCity}
                            currencyTo={currencyTo}
                            arrayToCurrency={arrayToCurrency}
                        />
                    </>
                :
                    <>
                        <WebPanel 
                            errorFrom={errorFrom}
                            errorTo={errorTo}
                            isFocusMoneyFrom={isFocusMoneyFrom} 
                            fromCity={fromCity} 
                            isOpenAutocomplete={isOpenAutocomplete} 
                            setIsOpenAutocomplete={setIsOpenAutocomplete}
                            handleCurrency={handleCurrency}
                            renderOptionAutocomplete={renderOptionAutocomplete}
                            arrayCountryCity={arrayCountryCity}
                            handleConvertCurrency={handleConvertCurrency}
                            onFocusMoney={onFocusMoney}
                            removeFocusMoney={removeFocusMoney}
                            handleSupportConvert={handleSupportConvert}
                            currencyFrom={currencyFrom}
                            arrayFromCurrency={arrayFromCurrency}
                            minOutCurrency={minOutCurrency}
                            toCity={toCity}
                            onSwap={onSwap}
                            currencyTo={currencyTo}
                            arrayToCurrency={arrayToCurrency}
                        />
                    </>
            }
        </>
    )
})
