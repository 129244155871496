import { Box, Typography } from "@mui/material";
import { DescriptionWrapper } from "components/descriptionWrapper/DescriptionWrapper";
import styles from './exchangeRules.module.scss';

export const ExchangeRules = () => {
    return (
        <Box >
            <DescriptionWrapper>
                <Typography variant="body1">
                    Правила обмена Empo.ai
                </Typography>
                <Box className={styles.container}>
                
                    <ol>
                        <li className={styles.liContent}>
                            <span className={styles.title}>Общие положения</span>

                            <ol>
                                <li>
                                        Настоящие условия использования регулируют порядок использования вами услуг, 
                                        предоставляемых сервисом <span className={styles.weight}> Empo.ai </span>
                                        (далее именуемым как «Empo.ai»).
                                </li>
                                <li>
                                    <span className={styles.weight}>Empo.ai </span>
                                    предоставляет услуги по обмену фиатной валюты на криптовалюту и 
                                    криптовалюты на фиатную валюту за пользователя 
                                    (далее именуемые «Услуги обмена»), а также услуги по 
                                    переводу денежных средств через криптовалюту USDT 
                                    (далее именуемые «Услуги перевода»). 
                                    Оба вида услуг далее будут именоваться как «Услуги».
                                    </li>
                                <li>
                                    Услуги доступны исключительно через платформу 
                                    <span className={styles.weight}> Empo.ai </span>
                                    (далее именуемую «Платформа»). 
                                    Платформа представляет собой частично автоматизированную онлайн-систему 
                                    (далее именуемую «Сайт»). Взаимодействуя с Платформой и пользуясь Услугами, 
                                    вы соглашаетесь с тем, что прочитали, 
                                    поняли и приняли все условия использования <span className={styles.weight}> Empo.ai</span>.
                                </li>
                                <li>
                                <span className={styles.weight}>Empo.ai </span> оставляет за собой право вносить изменения 
                                    в условия использования в одностороннем порядке. 
                                    Подобные изменения вступают в силу после их публикации на 
                                    Сайте и не распространяются на уже открытые сделки. Взаимодействие с Платформой 
                                    и использование Услуг означает ваше согласие с условиями использования, 
                                    опубликованными на Сайте на момент входа на Платформу.
                                    Если вы не согласны с условиями, опубликованными на Сайте, 
                                    вы должны немедленно прекратить использование Платформы и Услуг.
                                </li>
                            </ol>
                        </li>
                        <li className={styles.liContent}>
                            <span className={styles.title}> Порядок оказания услуг</span>
                            
                            <ol>
                                <li>
                                    <span className={styles.weight}>Создание заявки. </span> Заявки на обмен или перевод возможны 
                                    только после регистрации и авторизации на Сайте. 
                                    Клиент должен выбрать место отправки и получения средств 
                                    (страна, город) или криптовалюту, указать сумму и валюту 
                                    обмена, после чего пройти авторизацию через Telegram для 
                                    создания заявки.
                                </li>
                                <li>
                                    <span className={styles.weight}>Подтверждение условий. </span> 
                                    После создания заявки оператор<span className={styles.weight}> Empo.ai </span>свяжется с клиентом через 
                                    Telegram-чат для уточнения деталей операции. Оператор предоставит информацию о 
                                    курсе обмена, сумме отправки и получении средств. 
                                    Клиент должен подтвердить или отклонить предложенные условия.
                                </li>
                                <li>
                                    <span className={styles.weight}>Заморозка курса. </span>После подтверждения условий обмена курс 
                                    замораживается на 1 час. В этот период клиент обязан посетить 
                                    офис-кассу, указанный оператором, и завершить операцию.
                                </li>
                                <li>
                                    <span className={styles.weight}>Получение средств. </span> Клиент может получить наличные или криптовалюту 
                                    в офисе-кассе, указанном оператором, после завершения обмена или перевода.
                                </li>
                                <li>
                                <span className={styles.weight}>Изменение или отмена заявки. </span> 
                                Изменение или отмена заявки 
                                возможны до момента подтверждения условий обмена. 
                                После подтверждения любые изменения возможны только 
                                по согласованию с оператором.
                                </li>
                            </ol>
                        </li>
                        <li> 
                            <span className={styles.title}>Комиссии и курсы</span>

                            <ol>
                                <li>
                                    <span className={styles.weight}>Комиссии за услуги. </span> 
                                     Empo.ai взимает комиссии за проведение операций обмена 
                                    и перевода средств. Размер комиссии зависит от суммы и направления операции 
                                    и всегда указывается клиенту до подтверждения заявки.
                                </li>
                                <li>
                                    <span className={styles.weight}>Курсы обмена. </span> 
                                    Курсы обмена устанавливаются в реальном времени 
                                    на основе рыночной стоимости криптовалюты USDT и других валют. 
                                    Клиент обязан ознакомиться с курсом до подтверждения операции.
                                </li>
                            </ol>
                        </li>
                        <li> 
                            <span className={styles.title}>Права и обязанности сторон</span>
                          
                            <ol>
                                <li>
                                    <span className={styles.weight}>Права и обязанности клиента. </span> 
                                     Клиент обязуется использовать 
                                    Платформу в соответствии с настоящими правилами, предоставлять точную 
                                    информацию при создании заявки и выполнять все условия, согласованные с оператором.
                                </li>
                                <li>
                                    <span className={styles.weight}>Права и обязанности оператора.  Empo.ai </span>
                                     обязуется предоставлять клиенту 
                                    актуальную информацию о курсах обмена, сумме операции и адресах офисов-касс, 
                                    а также обеспечивать защиту и сохранность средств клиента до завершения операции.
                                </li>
                                <li>
                                    <span className={styles.weight}>Ограничение ответственности.  Empo.ai </span>
                                    не несет ответственности за задержки в выполнении операций, 
                                    вызванные техническими сбоями, форс-мажорными обстоятельствами или другими факторами, 
                                    не зависящими от оператора.
                                </li>
                            </ol>
                        </li>
                        <li> 
                            <span className={styles.title}>Безопасность и конфиденциальность</span>

                          <ol>
                            <li>
                                <span className={styles.weight}>Конфиденциальность данных.  Empo.ai </span> 
                                обеспечивает конфиденциальность всех данных, предоставляемых клиентом. 
                                Вся информация используется исключительно для выполнения обменных операций 
                                и не передается третьим лицам без согласия клиента.

                            </li>
                            <li>
                                <span className={styles.weight}>Меры безопасности. </span>
                                Все операции через
                                <span className={styles.weight}> Empo.ai </span> 
                                проходят через 
                                защищенные каналы связи. Рекомендуется использовать двухфакторную 
                                аутентификацию для повышения безопасности учетной записи.

                            </li>
                          </ol>
                        </li>
                        <li>  
                            <span className={styles.title}>Разрешение споров</span>

                            <ol>
                                <li>
                                    <span className={styles.weight}>Порядок урегулирования споров. </span> 
                                    Все споры и разногласия, возникающие в связи 
                                    с выполнением операций, будут решаться путем переговоров. 
                                    Если соглашение не будет достигнуто, споры разрешаются в суде по месту 
                                    нахождения <span className={styles.weight}> Empo.ai </span> в соответствии с законодательством.
                                </li>
                            </ol>
                        </li>
                        <li>  
                            <span className={styles.title}>Заключительные положения</span> 
                            

                             <ol>
                                <li>
                                    <span className={styles.weight}>Изменение правил. Empo.ai </span> 
                                    оставляет за собой право вносить изменения 
                                    в настоящие правила. Изменения вступают в силу с 
                                    момента их публикации на официальном сайте.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Box>
            </DescriptionWrapper>
        </Box>
    )
}
