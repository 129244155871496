import { Box, Typography } from "@mui/material";
import styles from './howToWork.module.scss';
import { CardSubstrate, Circle } from "components";
import {ReactComponent as ArrowFromTo} from 'assets/icon/arrowFromToSmall.svg';
import {ReactComponent as Logo} from 'assets/icon/logo.svg';

export const HowToWork = () => {
    return (
        <Box className={styles.workWrap}>
            <CardSubstrate styled={styles.card}>
                <Box className={styles.contentCard}>
                    <Typography variant='body1'>КАК РАБОТАЕТ СЕРВИС?</Typography>

                    <Box className={styles.moneyPath}>

                        <Circle name='RUB' description='Ваши наличные в Стране X'/>
                        <Box className={styles.arrowLine}>
                            <Typography className={styles.descriptionStep}>
                                Обмен наличных на USDT в стране X и пополнение аккаунта на EMPO.AI
                            </Typography>
                            <Box className={styles.fromToCard}>
                                <Typography className={styles.fromToText} variant='body2'>RUB</Typography>
                                <ArrowFromTo />
                                <Typography className={styles.fromToText} variant='body2'>USDT</Typography>
                            </Box>
                        </Box>
                        <Circle name={<Logo/>} description='Ваш аккаунт на Empo.ai' styled={styles.styledName}/>
                        <Box className={styles.arrowLine}>
                            <Typography className={styles.descriptionStep}>
                                Вывод с аккаунта на  EMPO.AI и обмен USDT на наличные в стране Y
                            </Typography>
                            <Box className={styles.fromToCard}>
                                <Typography className={styles.fromToText} variant='body2'>USDT</Typography>
                                <ArrowFromTo />
                                <Typography className={styles.fromToText} variant='body2'>USD</Typography>
                            </Box>
                        </Box>
                        <Circle name='USD' description='Получаете наличные в Стране Y'/>
                    </Box>
                </Box>
            </CardSubstrate>

        </Box>
    )
}