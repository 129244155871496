import { Box, Button } from '@mui/material'
import clsx from 'clsx'
import { MouseEvent} from 'react'
import styles from './listButtonStatus.module.scss'
import { ListStatusHistory } from 'pages/history/history.interface';
import { useIsMobileSize } from 'shared';

export interface PropsListButton {
    handleChangeActive: (e: MouseEvent<HTMLButtonElement>) => void;
    active: string;
    data: ListStatusHistory;
    translateStatusHistory: (name: string) => JSX.Element;
}


export const ListButtonStatus = (props: PropsListButton) => {
    const {active, data, handleChangeActive, translateStatusHistory} = props;
    const { isSizeMobile } = useIsMobileSize();

    return (
        <Box className={clsx(styles.listWrapper, !isSizeMobile && styles.fullScreen)}>
            <Button
                onClick={handleChangeActive}
                id='all' 
                className={clsx(styles.buttonList, active === 'all' && styles.active, !isSizeMobile && styles.marginFullScreen)}
            >
                {translateStatusHistory('all')}
            </Button>
            <Button
                onClick={handleChangeActive}
                disabled={data.inProgress === 0}
                id='inProgress' 
                className={clsx(styles.buttonList, active === 'inProgress' && styles.active)}
            >
                 {translateStatusHistory('inProgress')}
            </Button>
            <Button
                onClick={handleChangeActive}
                disabled={data.cancelled === 0}
                id='cancelled' 
                className={clsx(styles.buttonList, active === 'cancelled' && styles.active)}
            >
                {translateStatusHistory('cancelled')}
            </Button>
            <Button
                onClick={handleChangeActive}
                disabled={data.errored === 0}
                id='errored' 
                className={clsx(styles.buttonList, active === 'errored' && styles.active)}
            >
                  {translateStatusHistory('errored')}
            </Button>
            <Button
                onClick={handleChangeActive}
                disabled={data.successed === 0}
                id='successed' 
                className={clsx(styles.buttonList, active === 'successed' && styles.active)}
                >
                  {translateStatusHistory('successed')}
            </Button>
        </Box>
    )
}
