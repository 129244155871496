export interface ListStatusHistory {
    all: number;
    cancelled: number;
    errored: number;
    inProgress: number;
    successed: number;
}

export enum STATUS_TRANSFER {
    IN_PROGRESS = 'inProgress',
    SUCCESSED = 'successed',
    CANCELLED = 'cancelled',
    ERRORED='errored'
}

export interface ClientData {
    id: string;
    telegramId:  string;
    link: string;
}

export interface HistoryTransferItem {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    number: number;
    amountFrom: number;
    currencyFrom: string;
    amountTo: number;
    currencyTo: string;
    cityFrom: string;
    countryFrom: string;
    cityTo: string;
    countryTo: string;
    deliveryFrom: string;
    deliveryTo: string;
    status: STATUS_TRANSFER;
    cancelReason: null | string;
    clientId: string;
    client: ClientData;
}