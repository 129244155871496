import { Typography } from "@mui/material";

export const arrInternational = [
    {
      id: 0,
      summary: <Typography variant='subtitle1'>
                  1. Как работает система переводов через USDT в Empo.ai?
                </Typography>,
      details: <Typography variant='subtitle2'>
        Empo.ai организует переводы наличных через сеть офисов-касс, используя криптовалюту USDT. 
        Процесс следующий: вы приносите наличные средства в офис-кассу в одной стране, 
        где они конвертируются в USDT и передаются через наш сервис. 
        В другой стране получатель приходит в офис-кассу и получает наличные средства, 
        конвертированные обратно из USDT в местную валюту. Такой подход обеспечивает безопасность, 
        скорость и надежность всех переводов, без необходимости использования традиционных 
        банковских систем.
      </Typography>

    },
    {
      id: 1,
      summary: <Typography variant='subtitle1'>
                  2. Какие страны поддерживаются для международных переводов?

                </Typography>,
      details: <Typography variant='subtitle2'>
        Empo.ai поддерживает переводы наличных в более чем 150 странах мира. 
        Вот полный список стран, с которыми мы работаем: 
        Россия, Казахстан, Кыргызстан, Узбекистан, Азербайджан, 
        Молдова, Грузия, Армения, Турция, ОАЭ, Израиль, Франция, 
        Италия, Германия, Испания, Португалия, Греция, Черногория, 
        Сербия, Болгария, Румыния, Бельгия, Швейцария, Нидерланды, Чехия, 
        Польша, Австрия, Англия, Кипр, Словакия, Литва, Эстония, Латвия, Монако, 
        Финляндия, Египет, Гана, Конго, Мали, Бенин, Кот-д’Ивуар, Танзания, Кения, 
        Нигерия, Гамбия, Сенегал, Гвинея, Ангола, Сьерра-Леоне, Китай, Таиланд, Вьетнам, 
        Индонезия, Южная Корея, Япония, Гонконг, Сингапур, Индия, США, Канада, Перу, 
        Аргентина, Мексика, Панама.
        <br/>
        <br/>
        Мы постоянно расширяем список поддерживаемых стран, 
        чтобы сделать ваши международные переводы еще удобнее. 
        Подробную информацию о доступности переводов в каждой из этих стран вы можете найти 
        на нашем сайте или обратившись в службу поддержки.
      </Typography>

    },
    {
      id: 2,
      summary: <Typography variant='subtitle1'>
                  3. Какие документы необходимы для осуществления международного перевода?
                </Typography>,
      details: <Typography variant='subtitle2'>
       Для осуществления перевода вам потребуется удостоверение личности, 
       которое необходимо для подтверждения вашей личности при внесении и получении 
       наличных средств в офисе-кассе. Дополнительные документы могут потребоваться 
       в зависимости от суммы перевода и требований местного законодательства.
      </Typography>
    },
    {
      id: 3,
      summary: <Typography variant='subtitle1'>
                  4. Сколько времени занимает международный перевод?
                </Typography>,
      details: <Typography variant='subtitle2'>
      В большинстве случаев переводы через Empo.ai осуществляются практически мгновенно. 
      Однако, в зависимости от страны назначения и объема средств, процесс может занять от нескольких минут до пары часов. 
      Мы всегда стараемся обеспечить максимально быстрое обслуживание.
      </Typography>
    },
    {
      id: 4,
      summary: <Typography variant='subtitle1'>
                 5. Как я могу отследить статус своего перевода?
                </Typography>,
      details: <Typography variant='subtitle2'>
       Вы можете отследить статус вашего перевода через наш сайт или связавшись с поддержкой Empo.ai. 
       После внесения средств в офис-кассу вам будет предоставлен уникальный идентификатор транзакции, 
       который можно использовать для отслеживания.
      </Typography>
    },
  ]

export const arrCrypto = [
  {
    id: 0,
    summary: <Typography variant='subtitle1'>
                1. Как я могу купить USDT через Empo.ai?
              </Typography>,
    details: <>
      <Typography variant='subtitle2'>
        Чтобы купить USDT через Empo.ai, следуйте следующему флоу:
      </Typography>
      <ol>
        <li>
        Выберите место отправки — выберите страну и город, откуда вы хотите отправить наличные, или выберите криптовалюту
        </li>
        <li>
        Выберите место получения — выберите страну и город, где хотите получить криптовалюту, или выберите USDT.

        </li>
        <li>
        Впишите сумму, которую хотите получить, и выберите валюту для обмена.

        </li>
        <li>
        Нажмите "Продолжить обмен".
        </li>
        <li>
        Авторизуйтесь через Telegram.
        </li>
        <li>
        После этого создастся Telegram-чат, куда вы попадете при нажатии на кнопку "Продолжить". 
        Здесь вы сможете проверить данные о своей заявке.
        </li>
        <li>
        В чате будет бот Empo, который поможет вам, а также оператор сервиса. 
        Оператор предложит вам курс, сумму отправки и сумму получения. 
        Если условия вас устраивают, курс замораживается на 1 час, 
        и вам предоставляется адрес офиса, где вы можете оставить наличные.
        </li>
        <li>
        После того как вы внесете наличные, USDT будет зачислен на ваш криптовалютный кошелек
        </li>
      </ol>
    </>
  },
  {
    id: 1,
    summary: <Typography variant='subtitle1'>
                2. Как продать USDT через Empo.ai?
              </Typography>,
    details: <>
      <Typography variant='subtitle2'>
        Чтобы продать USDT через Empo.ai, следуйте следующему флоу:
      </Typography>
      <ol>
          <li>
              {'Выберите место отправки — выберите криптовалюту (USDT).'}
          </li>
          <li>
              Выберите место получения — выберите страну и город, где хотите получить наличные.
          </li>
          <li>
              Впишите сумму, которую хотите получить, и выберите валюту для обмена.
          </li>
          <li>
              Нажмите "Продолжить обмен".
          </li>
          <li>
              Авторизуйтесь через Telegram.
          </li>
          <li>
              После этого создастся Telegram-чат, куда вы попадете при нажатии на кнопку 
              "Продолжить". Здесь вы сможете проверить данные о своей заявке.
          </li>
          <li>
              В чате будет бот Empo, который поможет вам, а также оператор сервиса. 
              Оператор предложит вам курс, сумму отправки и сумму получения. 
              Если условия вас устраивают, курс замораживается на 1 час, и вам
              предоставляется адрес офиса, где вы можете получить наличные после передачи 
              USDT.
          </li>
          <li>
              После того как USDT будет переведен на указанный адрес, 
              вы сможете получить наличные средства в выбранном офисе.
          </li>
      </ol>
    </>
  },
  {
    id: 2,
    summary: <Typography variant='subtitle1'>
               3. Какие комиссии взимаются за покупку и продажу USDT?
              </Typography>,
    details: <Typography variant='subtitle2'>
     Empo.ai предлагает комфортные и прозрачные комиссии на покупку и продажу USDT. 
     Комиссия зависит от суммы операции и выбранной вами валюты. 
     Вы всегда сможете увидеть точную сумму комиссии перед подтверждением операции.
    </Typography>
  },
  {
    id: 3,
    summary: <Typography variant='subtitle1'>
                4. Безопасно ли покупать и продавать USDT через Empo.ai?
              </Typography>,
    details: <Typography variant='subtitle2'>
      Безопасность является нашим приоритетом. Все операции с USDT 
      проходят через защищенный блокчейн, что гарантирует прозрачность 
      и защиту ваших средств. Мы также используем дополнительные меры безопасности, 
      такие как двухфакторная аутентификация.
    </Typography>
  },
  {
    id: 4,
    summary: <Typography variant='subtitle1'>
               5. Какие преимущества использования USDT для международных переводов?
              </Typography>,
    details: <Typography variant='subtitle2'>
     Использование USDT для международных переводов имеет множество преимуществ: 
     скорость транзакций, низкие комиссии и защита от волатильности курса валют, 
     так как USDT привязан к доллару США. Это делает его идеальным инструментом 
     для глобальных финансовых операций через Empo.ai.
    </Typography>
  },
]