import { TextField } from '@mui/material';
import clsx from 'clsx';
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format';
import styles from './formInputMoney.module.scss'


export const FormInputMoney = (props: any) => {
    const { 
        control, 
        name, 
        className, 
        variant, 
        color, 
        placeholder, 
        fullWidth, 
        InputProps, 
        inputProps, 
        handleBlur,
        disabled,
        prefix,
        customOnChange,
        onFocus,
        onBlur,
        onSetFocus
    } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: {value, ...restField} }) => (
                <NumericFormat
                    onFocus={() => onFocus && onFocus(onSetFocus)}
                    onBlurCapture={() => onBlur && onBlur(onSetFocus)}
                    value={value}
                    customInput={TextField}
                    onBlur={() => !!handleBlur ?  handleBlur() : restField.onBlur()}
                    inputProps={inputProps}
                    InputProps={InputProps}
                    fullWidth={fullWidth} 
                    className={clsx(className, styles.styleInput)} 
                    placeholder={placeholder} 
                    variant={variant} 
                    color={color} 
                    disabled={disabled}
                    prefix={prefix && prefix}
                    thousandSeparator=" "
                    decimalSeparator=","
                    onValueChange={(values) => {
                        !!customOnChange && customOnChange(values.floatValue);
                        restField.onChange(values.floatValue);
                    }}
                />
            )}
        />
    )
}