import { ReactComponent as Logo} from 'assets/icon/logo.svg';
import styles from './header.module.scss'
import { Link, useNavigate } from 'react-router-dom';
import { Box,  IconButton,Slide} from '@mui/material';
import { useEffect, useState } from 'react';
import { Auth } from 'layout/auth/Auth';
import { NavBar } from './navBar';
import { Burger } from './burger/Burger';
import { useIsMobileSize } from 'shared';
import { UserData } from './header.interface';
import { clsx } from 'clsx';


export const Header = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<UserData>({
        tgFirstName: null,
        tgLastName: null,
        tgId:null,
        tgUsername: null,
        tgPhotoUrl: null,
    })



    const [isOpen, setIsOpen] = useState(false);
    const { isSizeMobile } = useIsMobileSize();

    const [isOpenMobileNav, setIsOpenMobileNav] = useState(!isSizeMobile);

    useEffect(()=> {
        if (isSizeMobile) {
            handleCloseMobileBar()
        }
    },[isSizeMobile])
    
    const bars = document.querySelectorAll('.bar');

    const handleCloseMobileBar = () => {
        if (isOpenMobileNav && isSizeMobile) {
            setIsOpenMobileNav(false);
            bars.forEach(bar => bar.classList.toggle('x'))
        }
    }

    const handleDrawerToggle = () => {
        setIsOpenMobileNav(prev => !prev);
        bars.forEach(bar => bar.classList.toggle('x'))
    };

    const handleOpenAuth = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }
  
    const login = ()=> {
  

        const users = {
            tgFirstName: sessionStorage.getItem('tgFirstName'),
            tgLastName: sessionStorage.getItem('tgLastName'),
            tgId: sessionStorage.getItem('tgId'),
            tgUsername: sessionStorage.getItem('tgUsername'),
            tgPhotoUrl: sessionStorage.getItem('tgPhotoUrl'),
        }
        setUser(users);
        setIsOpen(false);
    }
    
    useEffect(()=> {
        const users = {
            tgFirstName: sessionStorage.getItem('tgFirstName'),
            tgLastName: sessionStorage.getItem('tgLastName'),
            tgId: sessionStorage.getItem('tgId'),
            tgUsername: sessionStorage.getItem('tgUsername'),
            tgPhotoUrl: sessionStorage.getItem('tgPhotoUrl'),
        }
        setUser(users)
    },[]);

    const handleLogoutUser = () => {
        sessionStorage.clear();
        setUser({
            tgFirstName: null,
            tgLastName: null,
            tgId: null,
            tgUsername: null,
            tgPhotoUrl: null,
        })
        navigate("/");
    }



    return (
        <Box className={styles.header}>
            
            <nav className={styles.nav}>
                <Box className={styles.contentNav}>

                    <Link className={styles.linkLogo} to={'/'}>
                        <Logo />
                        <span className={styles.logoName}>EMPO</span>
                    </Link>
                    <IconButton
                        color="inherit"
                        aria-label="toggle slide"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={styles.mobileToggleMenu}
                    >
                        <Burger />
                    </IconButton>
                </Box>
                

                <Slide in={isOpenMobileNav} appear={false} timeout={0}>
                    <Box className={ clsx(styles.popup, isSizeMobile && isOpenMobileNav && styles.fadeInAnimation)} 
                        style={!isSizeMobile ? {visibility: "visible",  transform: 'translateY(0)'} : {}}
                    >
                        <NavBar onCloseMobileBar={handleCloseMobileBar} isMobileSize={isSizeMobile} user={user} onOpenAuth={handleOpenAuth} onLogout={handleLogoutUser} />
                    </Box>
                </Slide>
            </nav>

            {isOpen && 
                <Box sx={{ zIndex: 3000 }}>
                    <Auth handleBack={handleClose} login={login} handleBackTitle="ОТМЕНА" />
                </Box>
            }
        </Box>
    )
}
