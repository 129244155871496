import { Box, Button, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import styles from './NavBar.module.scss'
import clsx from 'clsx';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { NavBarProps } from "./navBar.interface";
import { TgButton } from "components";

export const NavBar = (props: NavBarProps) => {
    const {onLogout, onOpenAuth, user, isMobileSize, onCloseMobileBar} = props;

    const navigate = useNavigate();

    const onLink = (to:string) => {
        navigate(to);
        onCloseMobileBar();
    }

    const {pathname} = useLocation()

    return (
        <>
            <Box className={styles.navBarList}>
                <Button variant='text' className={clsx(pathname === '/' && styles.active, styles.link)}  onClick={() => onLink('/')}>
                    ГЛАВНАЯ
                </Button>
                <Button variant='text' className={clsx(pathname === '/about' && styles.active, styles.link)}  onClick={() => onLink('/about')}>
                    О СЕРВИСЕ
                </Button>
                <Button variant='text' className={clsx(pathname === '/FAQ' && styles.active, styles.link)} onClick={() => onLink('/FAQ')}>
                    FAQ
                </Button>
                <Button variant='text' className={clsx(pathname === '/exchange-rules' && styles.active, styles.link)} onClick={() => onLink('/exchange-rules')}>
                    ПРАВИЛА
                </Button>
            </Box>
            <Box className={styles.profile}>
                {!!!sessionStorage.getItem('tgId') 
                ? 
                    <Button
                        className={styles.buttonOpen} 
                        variant='text' 
                        onClick={onOpenAuth}
                        >
                        {isMobileSize ? 'ВОЙТИ ЧЕРЕЗ TELEGRAM' : 'ВОЙТИ'}
                    </Button>
                : 
                    <Box className={styles.userNameProfile}>
                        <Typography 
                            className={clsx(isMobileSize && styles.tgHide, styles.navUser)} 
                            variant='body2'
                        >
                            {user.tgFirstName || user.tgLastName || user.tgUsername}
                        </Typography>
                        <KeyboardArrowDownIcon className={clsx(isMobileSize && styles.tgHide)} color='success'/>
                        <Box className={styles.profileList}>
                            <Box className={styles.user}>
                                <Typography className={clsx(isMobileSize ? styles.logout : styles.navUser, styles.userName)} variant='body2'>{user.tgFirstName || user.tgLastName || user.tgUsername }</Typography>
                                <KeyboardArrowUpIcon className={clsx(isMobileSize && styles.tgHide)} color='success'/>
                            </Box>
                            <Button
                                onClick={() => onLink('/history')}
                                className={clsx(isMobileSize ? 
                                    styles.buttonLink 
                                    : 
                                    styles.buttonOpen,
                                    pathname === '/history' && styles.active, 
                                    styles.history
                                )} 
                                endIcon={<ManageSearchIcon className={clsx(isMobileSize && styles.tgHide)} fontSize='small' sx={{mb: '4px'}} />}
                            >
                                ИСТОРИЯ ЗАЯВОК
                            </Button>
                            <Button
                                className={clsx(isMobileSize ? styles.buttonLink : styles.buttonOpen, styles.logout)} 
                                variant='text' 
                                onClick={onLogout}
                                endIcon={<LogoutIcon fontSize='small' className={clsx(isMobileSize && styles.tgHide)} />}
                            >
                                ВЫЙТИ
                            </Button>
                        </Box>
                    </Box>
                }

                <Link href={'https://t.me/emposupport'} sx={{width: '100%'}}>
                    <TgButton classNames={isMobileSize ? styles.tgVisible: styles.tgHide} title='ЗАДАТЬ ВОПРОС'/>
                </Link>
            </Box>
        </>
        
    )
}