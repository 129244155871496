import { Box, Typography } from '@mui/material';
import styles from './welcomeCards.module.scss';
import { ReactComponent as LogoCard } from 'assets/icon/logoCard.svg';
import clsx from 'clsx';
import { TrustPilot } from 'components';

export const WelcomeCards = () => {
  return (
    <Box className={styles.wrapCards}>
      <Box className={clsx(styles.baseCard, styles.blueCard, styles.card)}>
        <Box className={styles.textContent}>
          <Typography className={clsx(styles.title, styles.textWhite)}>Самые выгодные<br/> условия от 0,2%</Typography>
          <Typography className={clsx(styles.subTitle, styles.textWhite, styles.margins)}>Безопасно. Быстро.<br/> По всему миру.</Typography>
          <Typography className={styles.description}>RUB - CRYPTO - USD</Typography>
        </Box>
        <LogoCard className={styles.hideLogo} />
      </Box>

      <Box className={styles.card}>
        <TrustPilot />
      </Box>
    </Box>
  )
}
