import { Box, CircularProgress } from '@mui/material'
import { SuccessCreatedOrder } from './successCreatedOrder';
import styles from './createdOrder.module.scss'
import clsx from 'clsx';

export const CreatedOrder = (props: any) => {
    const { handleBack, data, response, isLoading} = props;

  return (
    <Box className={clsx(styles.content, isLoading && styles.loading) }>
      {isLoading ?
        <CircularProgress size={80}/>
        :
        <SuccessCreatedOrder handleBack={handleBack} data={data} response={response} />
      }
       
    </Box>
  )
}
