import { STATUS_TRANSFER } from "pages/history/history.interface";

export const statusTranslete = (status: STATUS_TRANSFER) => {
    switch (status) {
        case STATUS_TRANSFER.IN_PROGRESS:
            return 'В ПРОЦЕССЕ'
        case STATUS_TRANSFER.CANCELLED:
            return 'ОТМЕНЕННА'
        case STATUS_TRANSFER.ERRORED:
            return 'ОШИБКА'
        case STATUS_TRANSFER.SUCCESSED:
            return 'ЗАВЕРШЕНА'
    
        default:
            return 'НЕИЗВЕСТНЫЙ'
    }
}



export const dateFormatter = (create: Date) => {
    const monthNames = [
        "января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];
    const date = new Date(create);
    ;
    return `${date.getDate()} 
    ${monthNames[date.getUTCMonth()]} 
    ${date.getFullYear()} в ${date.getHours()}:${date.getMinutes()}`;
}

export const formatMoney = (sum: number) => {
    return Intl.NumberFormat('ru-RU').format(sum);
}
  