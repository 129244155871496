import { MouseEvent, useState,  } from 'react'
import { ListHistoryProps } from './listHistory.interface';
import { Box } from '@mui/material';
import styles from './listHistory.module.scss'
import { CustomPopup } from 'components';
import { ListButtonStatus } from './listButtonStatus';

export const ListHistory = (props:ListHistoryProps) => {
    const { data, active, setActive } = props;

    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const bars = document.querySelectorAll('.line');

    const handleClosePopup = () => {
        setIsOpenPopup(false);
        bars.forEach(bar => bar.classList.toggle('cross'))
    }

    const handleTogglePopup = () => {
        setIsOpenPopup(prev => !prev);
        bars.forEach(bar => bar.classList.toggle('cross'));
    };


    const handleChangeActive = (e: MouseEvent<HTMLButtonElement>) => {
        setActive(e.currentTarget.id)
    }

    const handleChangeActiveMobile = (e: MouseEvent<HTMLButtonElement>) => {
        setActive(e.currentTarget.id);
        handleClosePopup()
    }
    

    const translateStatusHistory = (name: string) => {
        switch (name) {
            case 'all':
                return <>ВСЕ &nbsp;<span className={styles.amount}>{data.all}</span></>
            case 'inProgress':
                return <>В ПРОЦЕССЕ &nbsp;<span className={styles.amount}>{data.inProgress}</span></>
            case 'cancelled':
                return <>ОТМЕНЕНА &nbsp;<span className={styles.amount}>{data.cancelled}</span></>
            case 'errored':
                return <>ОШИБКА &nbsp;<span className={styles.amount}>{data.errored}</span></>
            case 'successed':
                return <>ЗАВЕРШЕНА &nbsp;<span className={styles.amount}>{data.successed}</span></>
        
            default:
                return <>ВСЕ <span className={styles.amount}>{data.all}</span></>
        }
    }



    return (
        <Box className={styles.mainListHistory}>
            <Box className={styles.wrapPopup}>
                <CustomPopup 
                    title={active} 
                    translate={translateStatusHistory} 
                    isOpenPopup={isOpenPopup} 
                    handleTogglePopup={handleTogglePopup} 
                    panel={
                        <ListButtonStatus data={data} 
                            active={active} 
                            handleChangeActive={handleChangeActiveMobile}  
                            translateStatusHistory={translateStatusHistory} 
                        />
                    } 
                />
            </Box>
             <Box className={styles.wrapWebPanel}>
                <ListButtonStatus 
                    data={data} 
                    active={active} 
                    handleChangeActive={handleChangeActive} 
                    translateStatusHistory={translateStatusHistory}
                />
            </Box>
            {isOpenPopup && 
                <Box className={styles.blur}/>
            }
        </Box>
    )
}