import { Box, Button, Divider } from "@mui/material"
import SwapVertIcon from '@mui/icons-material/SwapVert';
import styles from './dividerSwapButton.module.scss'
import { MouseEvent } from "react";

export interface SwapProps {
    isError: boolean;
    onSwap: (e:MouseEvent<HTMLButtonElement>) => void;
    isDisabled: boolean;
}

export const DividerSwapButton = (props: SwapProps) => {

    const {isError, isDisabled, onSwap} = props;

    return (
        <>
            {isError && <Box className={styles.divider}>
                    <Divider sx={{width: '100%', height: '1.2px' }}/>
                    <Button 
                        disabled={isDisabled} 
                        onClick={(e) => onSwap(e)} className={styles.swapButton} 
                        endIcon={<SwapVertIcon/>}
                    >
                        CRPT
                    </Button>
            </Box>}
        </>
    )
}