import { Box, Typography } from '@mui/material'
import clsx from 'clsx';
import { ReactComponent as BlackStar } from 'assets/icon/blackStar.svg';
import styles from './trustPilot.module.scss';
import { Link } from 'react-router-dom';
import { TRUST_PILOT_LINK } from 'shared';

export interface TrustPilotProps {
    styled?: string;
    hidden?: boolean;
}

export const TrustPilot = (props: TrustPilotProps) => {
    const {
        styled,
        hidden
    } = props;

    return (
        <Link style={{display: hidden ? 'none': 'block'}} className={styles.link} to={TRUST_PILOT_LINK}>
            <Box className={clsx(styles.baseCard, !!styled ? styled : styles.grayCard)}>
                <Box>
                    <Typography className={clsx(styles.title, styles.textBlack)}>Оцените на</Typography>
                    <Box className={styles.cardContainer}>
                        <BlackStar/>
                        <Typography className={clsx(styles.title, styles.textBlack)}>TRUSTPILOT</Typography>
                    </Box>
                </Box>
                <Typography className={clsx(styles.subTitle, styles.grayText)}>ВЫБОР ПОЛЬЗОВАТЕЛЕЙ.</Typography>
            </Box>
        </Link>
    )
}
