import { Box, IconButton, Typography } from '@mui/material';
import { PopupProps } from './customPopup.interface';
import styles from './customPopup.module.scss'
import '../../../App.css';


export const CustomPopup = (props: PopupProps) => {
    const {title, translate, isOpenPopup,  handleTogglePopup, panel} = props;

    return (
        <Box className={styles.containedPopup}>
            <Box className={styles.popup} onClick={handleTogglePopup}>
                <Typography className={styles.title}>{translate ? translate(title) : title}</Typography>
                <IconButton
                    className={styles.toggleButton}
                    color="inherit"
                    aria-label="toggle popup"
                    edge="start"
                   
                >
                    <div className='toggle-line'>
                        <span className='line'></span>
                        <span className='line'></span>
                        <span className='line'></span>
                    </div>
                </IconButton>
            </Box>
            {isOpenPopup && 
                <Box className={styles.wrapPanel}>
                    {panel}
                </Box>
            }
        </Box>
    )
}
