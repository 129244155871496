import { Box, Slide } from '@mui/material';
import styles from './mobileSelectPortal.module.scss';
import { createPortal } from 'react-dom';

export const MobileSelectPortal = (props:any) => {
    const { children, stateIsOpen } = props;

    return (
        <>
            {createPortal(
                <Slide in={stateIsOpen} timeout={200} direction='up'>
                    <Box className={styles.wrapSelect}  >
                        {children}
                    </Box>
                </Slide>,
                document.body
            )}
        </>
    )
}
