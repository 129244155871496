import { Box } from '@mui/material';
import { CardSubstrate } from 'components/cardSubstrate';
import { DescriptionAppProps } from './descriptionWrapper.interface';
import styles from './descriptionWrapper.module.scss';

export const DescriptionWrapper = (props: DescriptionAppProps) => {


    return (
        <CardSubstrate styled={styles.changeCard}>
            <Box className={styles.content}>
                {props.children}
            </Box>
        </CardSubstrate>
    )
}
